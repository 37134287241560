import React from 'react'

const Home = () => (
  <div className="chartContainer">
    <h1>Plot Device</h1>
    <h3>A collection of dataviz projects by <a href="http://bengarvey.com">Ben Garvey</a></h3>
    <ul className="list">
      <li className="list-item"><a href="auto">US Automobile Fatalities 1899 - 2015</a></li>
      <li className="list-item"><a href="lbs">Weight Over Time</a></li>
      <li className="list-item"><a href="libraries">Chart Creation Technologies</a></li>
      <li className="list-item"><a href="pagerduty">Pager Duty Incidents</a></li>
      <li className="list-item"><a href="tornadoes">US Tornado Deaths 1875 - 2017</a></li>
      <li className="list-item"><a href="cooplovescostumes">#CoopLovesCostumes</a></li>
      <li className="list-item"><a href="starwars">Star Wars Network (No Last Jedi Spoilers)</a></li>
      <li className="list-item"><a href="movies">My Top 100 Favorite Movies</a></li>
      <li className="list-item"><a href="steps">My Steps</a></li>
      <li className="list-item"><a href="rj">RJMetrics: Where are they now?</a></li>
      <li className="list-item"><a href="nleast">NLEast 2007</a></li>
      <li className="list-item"><a href="cities">Quantified Negadelphia</a></li>
      <li className="list-item"><a href="wire">HBO's The Wire (Spoilers)</a></li>
      <li className="list-item"><a href="cafe">Owen's Cafeteria Expenses</a></li>
      <li className="list-item"><a href="meetup">Meetup Hex Colors</a></li>
      <li className="list-item"><a href="dungeonadventure">Dungeon Adventure Sales</a></li>
      <li className="list-item"><a href="autodeaths">US Auto Deaths 1899 - 2018</a></li>
      <li className="list-item"><a href="starwars2">Star Wars Network (Spoilers)</a></li>
      <li className="list-item"><a href="pizza">Pizza</a></li>
    </ul>
    <div>
      <ul className="navList">
        <li className="navListItem"><a href="mailto:ben@bengarveycom">Contact</a></li>
        <li className="navListItem"><a href="https://twitter.com/bengarvey">Twitter</a></li>
      </ul>
    </div>
  </div>
)

export default Home
